@mixin clearfix() {
  *zoom: 1;
  &:before,
  &:after {
    display: table;
    content: '';
  }
  &:after {
    clear: both;
  }
}

@mixin reset-spacing() {
  margin: 0;
  padding: 0;
}

@mixin reset-list() {
  display: block;
  @include reset-spacing;
}

@mixin vertical-center-text($height) {
  height: $height;
  line-height: $height;
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin size-restricted($width, $height: $width) {
  width: $width;
  max-width: $width;
  height: $height;
  max-height: $height;
}

@mixin button-theme($bg-color, $fg-color) {
  $hover-offset: 10%;
  $hover-color: darken($bg-color, $hover-offset);

  @if lightness($bg-color) < lightness($fg-color) {
    $hover-color: lighten($bg-color, $hover-offset);
  }

  background-color: $bg-color;
  color: $fg-color;

  &:hover,
  &:focus,
  &:active {
    background-color: $hover-color;
    color: $fg-color;
  }

  &:visited {
    color: $fg-color;
  }
}

@mixin link-theme($color) {
  $hover-offset: 10%;
  $hover-color: lighten($color, $hover-offset);

  @if lightness($color) > lightness($color-grey) {
    $hover-color: darken($color, $hover-offset);
  }

  text-decoration: none;
  color: $color;

  &:hover,
  &:hover:visited,
  &:active,
  &:active:visited,
  &:focus,
  &:focus:visited {
    text-decoration: none;
    color: $hover-color;
  }

  &:visited {
    text-decoration: none;
    color: $color;
  }
}

/* Responsive */
@mixin from-sm() {
  @media (min-width: $screen-sm-min) {
    @content;
  }
}

@mixin from-md() {
  @media (min-width: $screen-md-min) {
    @content;
  }
}

@mixin from-lg() {
  @media (min-width: $screen-lg-min) {
    @content;
  }
}

@mixin from-xl() {
  @media (min-width: $screen-xl-min) {
    @content;
  }
}
