.section {
  padding: $spacing-lg 0;
}

.section--code {
  background: url('../images/code-bg.jpg') no-repeat center 50%;
  box-shadow: 0 8px 20px lighten($color-black, 5%) inset;
  background-size: cover;
  color: $color-white;
}

.section--dark {
  color: $color-white;
  background-color: $color-dark;
}

@include from-md {
  .section {
    padding: $spacing-xl 0;
  }

  .section--code {
    background-attachment: fixed;
  }
}
