*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  position: relative;
  font-size: 1.6rem;
  line-height: 1.6;
  color: $color-text;
  font-family: $standard-font;
  margin: 0;
}

a {
  @include link-theme($color-dark);
}

h1,
h2,
h3,
h4 {
  font-family: $heading-font;
  margin-bottom: $spacing-md;
  line-height: 1.3;

  small {
    opacity: 0.5;
    font-size: 0.7em;
    font-weight: normal;
  }
}

h2 {
  font-size: 3.4rem;
}

h3 {
  font-size: 3rem;
}

p {
  margin-bottom: $spacing-md;
}

.container-fluid {
  padding: 0 $spacing-md;
}

@include from-md {
  .container-fluid {
    padding: 0 $spacing-lg;
    max-width: 100rem;
    margin: 0 auto;
  }
}
