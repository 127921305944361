.publications {
  padding: 0;
  list-style: none;
}

.publications__item {
  margin-bottom: $spacing-xs;
}

@include from-sm {
  .publications {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -#{$spacing-xs};
  }

  .publications__item {
    width: calc(50% - #{$spacing-sm});
    margin: 0 $spacing-xs $spacing-xs;
  }
}

@include from-lg {
  .publications {
    margin: 0 -#{$spacing-xs};
  }

  .publications__item {
    width: calc(33.3% - #{$spacing-sm});
    margin: 0 $spacing-xs $spacing-md;
  }
}
