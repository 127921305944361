.button,
.button:visited {
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.2s ease;
  border: 2px;
  padding: $spacing-sm $spacing-md;
  border-radius: 99rem;
  font-weight: bold;
  font-size: 1.6rem;
  margin: 0;
  text-decoration: none;

  &:active,
  &:hover,
  &:focus {
    text-decoration: none;
    box-shadow: 0 0 0 $color-white;
  }

  @include button-theme($color-dark, $color-white);
}

.button--list-item {
  width: 100%;
  margin-bottom: $spacing-xs;
}

.button--small {
  font-size: 1.4rem;
  padding: $spacing-xs $spacing-sm;
}

.button__icon {
  margin-right: $spacing-xs;
}

.button--link,
.button--link:visited {
  @include button-theme($color-lightgrey, $color-dark);
}


@include from-sm {
  .button--list-item {
    width: auto;
    margin-right: $spacing-xs;
    margin-bottom: $spacing-sm;
  }
}
