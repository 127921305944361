.info {
  padding: 0;
  list-style: none;
}

.info__portrait {
  text-align: center;
}

@include from-sm {
  .info {
    display: flex;
  }

  .info__portrait {
    padding-top: $spacing-md;
    min-width: 30%;
    max-width: 30%;
  }

  .info__content {
    margin-left: $spacing-md;
  }
}

@include from-lg {
  .info__portrait {
    min-width: 25%;
    max-width: 25%;
  }
}
