/* Fonts */

@font-face {
  font-family: Bariol;
  src: url(../fonts/bariol.woff);
}

@font-face {
  font-family: Bariol;
  src: url(../fonts/bariol-italic.woff);
  font-style: italic;
}

@font-face {
  font-family: Bariol;
  src: url(../fonts/bariol-bold.woff);
  font-weight: bold;
}

$heading-font: 'Bariol', Helvetica, Arial, sans-serif;
$standard-font: 'Open Sans', Helvetica, Arial, sans-serif;

/* Colors */
$color-white: #ffffff;
$color-black: #000000;
$color-dark: lighten($color-black, 7%);
$color-grey: darken($color-white, 50%);
$color-lightgrey: darken($color-white, 14%);
$color-text: $color-dark;
$color-blue: #3498db;

/* Spacing */
$spacing-base: 0.8rem;

$spacing-xxs: $spacing-base * 0.5;
$spacing-xs: $spacing-base * 1;
$spacing-sm: $spacing-base * 2;
$spacing-md: $spacing-base * 3;
$spacing-lg: $spacing-base * 4;
$spacing-xl: $spacing-base * 5;

/* Z-Index */
$z-index-top-bar: 100;

/* Screen sizes */
$screen-sm-min: 576px;
$screen-md-min: 737px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
