.top-bar {
  background-color: $color-dark;
  color: $color-white;
}

.top-bar__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing-md 0;
}

.top-bar__title {
  font-weight: bold;
  font-size: 2.6rem;
  text-transform: lowercase;
  word-spacing: -0.5ex;
  white-space: nowrap;
  margin: 0;
}

.top-bar__link {
  @include link-theme($color-white);

  padding: 0 $spacing-xs;
  font-size: 1.8rem;

  &:hover {
    opacity: 0.8;
  }
}

@include from-sm {
  .top-bar__title {
    font-size: 3.2rem;
  }

  .top-bar__content {
    padding: $spacing-xl 0;
  }
}
