.intro {
  background: $color-lightgrey url('../images/header-bg.jpg') no-repeat
    0 center;
  background-size: cover;
  padding-top: 20%;
  padding-bottom: 20%;
}

.intro__quote-circle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px;
  width: 24rem;
  height: 24rem;
  border-radius: 50%;
  background-color: $color-white;
  text-align: center;
}

.intro__quote {
  position: relative;
  font-family: $heading-font;
  margin: 0;
  font-size: 3rem;
  line-height: 1.4;
  top: -0.8rem;
}

.intro__quote-big {
  font-size: 4rem;
}

@include from-md {
  .intro {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .intro__quote-circle {
    width: 30rem;
    height: 30rem;
  }

  .intro__quote {
    font-size: 3.6rem;
  }

  .intro__quote-big {
    font-size: 4.8rem;
  }
}
